<template>

  <div v-show="!hidden" class="">

    <!-- <Fixture v-bind:fixture="fixture" v-bind:live="live"></Fixture> -->

    <fixture v-bind:fixture="fixture" v-bind:live="live"></fixture>

    <div class="timeline-wrapper d-none" v-show="busy">

      <div class="timeline-item">

        <div class="animated-background facebook">

          <!-- lines -->

          <div class="background-masker" style="top: 0px; left: 0px; width: 60%; height: 7px; "></div>

          <div class="background-masker" style="top: 17px; left: 0px; width: 60%; height: 7px; "></div>

          <div class="background-masker" style="top: 34px; left: 0px; width: 60%; height: 7px; "></div>

          <div class="background-masker" style="top: 51px; left: 0px; width: 60%; height: 7px; "></div>

          <div class="background-masker" style="bottom: 0px; left: 0px; width: 60%; height: 5px; "></div>

          <!-- odds separator -->
          <div class="background-masker" style="top: 0; bottom: 0; left: 59%; width: 1%; "></div>

          <div class="background-masker" style="top: 0px; right: 0px; width: 40%; height: 7px; "></div>
          <div class="background-masker" style="bottom: 0px; right: 0px; width: 40%; height: 7px; "></div>

          <!-- odds separator -->
          <div class="background-masker" style="top: 0; bottom: 0; left: 79%; width: 1%; "></div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
import fixture from "./Fixture.vue";

export default {
name: "Highlight",
mounted: function () {},
methods: {
  setBusy: function () {
    this.busy = true;
  },
  unBusy: function () {
    this.busy = false;
  },
},
components: {
  fixture,
},
data: function () {
  return {
    busy: false,
    hidden: false,
  };
},
props: {
  fixture: {
    required: true,
  },
  live: {
    required: false,
    default: false,
  },
},
};
</script>
